import React from "react";
import { Calendar } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import locale from "antd/lib/calendar/locale/zh_CN.js";
import { withTranslation } from "react-i18next";
import { dropDownImg,dropDownOnImg,dropDownOnLightImg } from '@/asserts/imgs/common/index';
import TipsComponents from '../../modal/tips/index';
import APPstyles from '@/App.less';
import styles from './index.less';

class CalendarIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCalShow: false,
      selectVal: '',
      callbackDateLeft:'',
      callbackDateRight:'',
      minW420:420,
      minW390:390,
      getLeftCalData:['',''],
      getRightCalData:['',''],
      getCalTag:'left',
      calendarL:0,
      calendarT:40,
      calendarW:372,
      leftImg:dropDownImg,
      rightImg:dropDownImg,
      tipsVisible: false,
      tipsTitle: '',
    }
  }
  //日历--显示/隐藏
  onCalClick(tag,e) {
    const {from_date,to_date}=this.props
    const {getLeftCalData,getRightCalData,getCalTag}=this.state
    e.stopPropagation()
    //日历里面显示对应到选中的日期
    let getCalVal = ''
    if (tag == 'left') {
      getCalVal=getLeftCalData[1] ? moment(getLeftCalData[1]) : moment(from_date)
    } else {
      getCalVal=getRightCalData[1] ? moment(getRightCalData[1]) : moment(to_date)
    }
     this.setState((state) => ({
      isCalShow:getCalTag==tag?!state.isCalShow:true,
      selectVal: getCalVal,
      getCalTag:tag
    }))
    this.props.cbCloseInput()
    this.changeCalendarData(tag)
  }
  // 选中日期
  onSelectCalendar(e) {
    const { sign, ChgLangData } = this.props;
    const { getCalTag, getLeftCalData, getRightCalData } = this.state;
    const selectedDate = moment(e);
    // 对于左侧日历
    if (getCalTag === 'left') {
      const rightDate = moment(getRightCalData[1], 'YYYYMMDD');
      const oneYearBeforeRightDate = moment(rightDate).subtract(1, 'years');
      // 确保左侧选择的日期不晚于右侧日期
      if (selectedDate.isAfter(rightDate)) {
        this.setState({
          tipsVisible: true,
          tipsTitle: ChgLangData == "zh-CN"?'开始日期不能晚于结束日期':'The start date cannot be later than the end date',
        });
        return;
      }
      // 确保左侧日期不超过右侧日期一年的限制
      if (sign !== 'campagin' && selectedDate.isBefore(oneYearBeforeRightDate)) {
        this.setState({
          tipsVisible: true,
          tipsTitle: ChgLangData == "zh-CN"?'查詢日期區間不可超過一年':'Query date range cannot exceed one year',
        });
        return;
      }
    }
    // 对于右侧日历
    if (getCalTag === 'right') {
      const leftDate = moment(getLeftCalData[1], 'YYYYMMDD');
      const oneYearAfterLeftDate = moment(leftDate).add(1, 'years');
      // 确保右侧选择的日期不早于左侧日期
      if (selectedDate.isBefore(leftDate)) {
        this.setState({
          tipsVisible: true,
          tipsTitle: ChgLangData == "zh-CN"?'结束日期不能早于开始日期':'The end date cannot be earlier than the start date',
        });
        return;
      }
      // 确保右侧日期不超过左侧日期一年的限制
      if (sign !== 'campagin' && selectedDate.isAfter(oneYearAfterLeftDate)) {
        this.setState({
          tipsVisible: true,
          tipsTitle: ChgLangData == "zh-CN"?'查詢日期區間不可超過一年':'Query date range cannot exceed one year',
        });
        return;
      }
    }
    // 如果验证通过，则更新状态
    const getDateValue = selectedDate.format('YYYYMMDD');
    let readDate = getDateValue.slice(2, 4) + '/' + getDateValue.slice(4, 6) + '/' + getDateValue.slice(6);
    if (getCalTag === 'left') {
      setTimeout(() => {
        this.setState({
          getLeftCalData: [readDate, getDateValue],
          isCalShow: false,
        });
        this.props.callbackDateData([getDateValue, getRightCalData[1]]);
      }, 10);
    } else {
      setTimeout(() => {
        this.setState({
          getRightCalData: [readDate, getDateValue],
          isCalShow: false,
        });
        this.props.callbackDateData([getLeftCalData[1], getDateValue]);
      }, 10);
    }
  }
  //初始化--切换语言
  onLoadDataFun() {
    const {from_date,to_date}=this.props
    let getFromDate=from_date.slice(2,4)+'/'+from_date.slice(4,6)+'/'+from_date.slice(6)
    let getToDate=to_date.slice(2,4)+'/'+to_date.slice(4,6)+'/'+to_date.slice(6)
    
    this.setState({
      getLeftCalData:[getFromDate,from_date],
      getRightCalData:[getToDate,to_date]
    })
  }
  //更改日历
  changeCalendarData(tag){
    const {bodyW,mobilebodyW}=this.props
    const {minW420}=this.state
    let left=bodyW>mobilebodyW?tag=='left' ? 0 : 90:10
    let top=bodyW<=minW420?52:40
    let width=bodyW<=minW420?'94%':372
    this.setState({
      calendarL: left,
      calendarT: top,
      calendarW:width
    })
  }
  //点击外部关闭下拉框
  handleCalendarClick = e => {
    if(!this.calendarComponent.contains(e.target)) {
      this.setState({
        isCalShow:false
      })
    }
  }
  handleMouseOver = (imgKey) => {
    const {isPC,ChgThemeData}=this.props;
    if (!isPC) return;
    const imgurl = ChgThemeData === 'Light' ? dropDownOnLightImg : dropDownOnImg;
    this.setState({ [imgKey]: imgurl });
  };
  handleMouseOut = (imgKey, defaultImg) => {
    const {isPC}=this.props;
    if (!isPC) return;
    this.setState({ [imgKey]: defaultImg });
  };
  // 监听组件传递的值
  componentDidUpdate(newProps){
    const {bodyW,accountIndex,from_date,to_date,closeCalendar,sign}=this.props
    const {getCalTag}=this.state
    //tab栏切换--恢复原来的
    if(accountIndex!==newProps['accountIndex']){
      let getFromDate=from_date.slice(2,4)+'/'+from_date.slice(4,6)+'/'+from_date.slice(6)
      let getToDate=to_date.slice(2,4)+'/'+to_date.slice(4,6)+'/'+to_date.slice(6)

      this.setState({
        isCalShow:false,
        getLeftCalData:[getFromDate,from_date],
        getRightCalData:[getToDate,to_date]
      })
    }
    //关闭下拉框
    if(closeCalendar!==newProps['closeCalendar']){
      this.setState ({
        isCalShow:false
      })
    }
    if(bodyW!==newProps['bodyW']){
      this.changeCalendarData(getCalTag)
    }
    // campagin
    if(from_date!==newProps['from_date']){
      sign=='campagin'&&this.onLoadDataFun();
    }
  }
  //初始化
  componentDidMount() {
    const {getCalTag}=this.state;
    this.onLoadDataFun();
    this.changeCalendarData(getCalTag);
    document.addEventListener('click', this.handleCalendarClick);
  }
  //卸载
  componentWillUnmount() {
    document.removeEventListener('click', this.handleCalendarClick);
  }
  render() {
    const { t,bodyW,ChgThemeData,ChgLangData,isPC,isMobile,sign} = this.props
    const {
      isCalShow,
      selectVal,
      minW420,
      minW390,
      getLeftCalData,
      getRightCalData,
      getCalTag,
      calendarL,
      calendarT,
      calendarW,
      leftImg,
      rightImg,
      tipsVisible,
      tipsTitle,
    } = this.state;
    return (
      <div 
        className={[
          styles.calendarcon,
          ChgThemeData=='Light'?styles.calendarcon_light:'',
          (sign=='Overseas'&&!isPC)||(sign=='campagin'&&isMobile)?styles.calendarOverseas:'',
        ].join(" ")}
        style={{ 
          position: bodyW<=minW420?'static':'relative',
          marginRight:8,
        }}
      >
        <div 
          className={[
            APPstyles.flex_row,
            styles.calendar,
          ].join(" ")} 
          style={{
            padding:bodyW>minW390?'0 16px':'0 8px',
            width:sign=='Statement'?178:bodyW>minW390?190:168
          }}
        >
          <div
            className={[APPstyles.flex_row, styles.calendar_left].join(" ")}
            onClick={this.onCalClick.bind(this,'left')} 
            onMouseOver={() => this.handleMouseOver('leftImg')}
            onMouseOut={() => this.handleMouseOut('leftImg',dropDownImg)}
          >
            <span>{getLeftCalData[0]}</span>
            <img
              src={leftImg}
              alt="dropdown"
              className={isCalShow&&getCalTag=='left' ? styles.imgRotate : ""}
            />
          </div>
          <span style={{fontSize:isMobile?12:10}}>{t('to')}</span>
          <div
            className={[APPstyles.flex_row, styles.calendar_left].join(" ")}
            onClick={this.onCalClick.bind(this,'right')} 
            onMouseOver={() => this.handleMouseOver('rightImg')}
            onMouseOut={() => this.handleMouseOut('rightImg',dropDownImg)}
          >
            <span>{getRightCalData[0]}</span>
            <img
              src={rightImg}
              alt="dropdown"
              className={isCalShow&&getCalTag=='right' ? styles.imgRotate : ""}
            />
          </div>
        </div>
        <div
          className={styles.calendar_pick}
          style={{
            display: isCalShow ? "" : "none",
            left:calendarL, 
            top:calendarT,
            width:calendarW
          }}
          onClick={(e)=>{
            e.stopPropagation()
            this.setState ({
              isCalShow:true
            })
          }}
          ref = {testComponent => this.calendarComponent = testComponent} 
        >
          <Calendar
            fullscreen={false}
            value={selectVal}
            locale={ChgLangData == "zh-CN" ? locale : ""}
            onSelect={this.onSelectCalendar.bind(this)}
          />
          {tipsVisible &&
            <TipsComponents 
              {...this.props}
              visible={tipsVisible}
              tipsTitle={tipsTitle}
              onCloseModal={()=>{
                this.setState ({
                  tipsVisible:false,
                });
              }} 
            />
          }
        </div>
      </div>
    )
  }
}
export default withTranslation()(CalendarIndex);
