import React from 'react';
import { withTranslation } from 'react-i18next';
import common from '@/asserts/js/common';
import { 
  searchImg,
  searchOnImg,
  searchOnLightImg,
} from '@/asserts/imgs/common/index';
import Header from '@/components/Navigation/Header/index';
import Slider from '@/components/Navigation/Slider/index';
import SlidingPanel from '@/components/Navigation/SlidingPanel/index';
import Tabs from '@/components/Tabs/index';
import OrderMask from '@/components/Trade/OrderMask/index'
import SearchCon from '@/components/Quote/Add/index';
import AlertsCountButton from '@/components/Alerts/components/AlertsCountButton';
import ListCon from './list/index';
import APPstyles from '@/App.less';
import styles from './index.less';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabsData:[
        {name:'Campagin',isShow:false,id:'Campagin'},
      ],
      sliderIndex:'account',
      from_date:'',
      to_date:'',
      ChgLangData:'zh-CN',
      ChgThemeData:'Dark',
      isSlidingPanelShow:false,
      tradeData:[false,false,false,false],
      tradeOrderData:[],
      accountTradeData:[],
      isTradeShow:true,
      IsSubmitOrder:false,
      isSearchPageShow:false,
      isOpenAlertModalInTabletMode: false,
      chgSearchImg:searchImg,
      watchListData:[],
      isWatchlistShow:true,
      isGotoPageShow:false,
      tradeName:'',
      isSwitchModalShow:false,
    }
    this.myHeaderRef = React.createRef()
  }
  // 获取路由跳转--app.js
  callbackPathnameApp(data){
    this.props.callbackPathnameApp(data)
  }
  //slider--更改navIndex
  callbackChgNavIndex(id,isClose){
    let tradeData=!isClose&&(id=='trade'||id=='alerts')?[true,true,false,false]:[false,false,false,false]
    if(!isClose&&id==='trade'){this.setState({isOpenAlertModalInTabletMode:false})}
    if(!isClose&&id==='alerts'){this.setState({isOpenAlertModalInTabletMode:true})}
    this.setState({
      sliderIndex:id,
      tradeData:tradeData
    })
  }
  //是否显示左侧的menu
  callbackShowSlidingPanel(data){
    this.setState({
      isSlidingPanelShow:data
    })
  }
  //trade--show--portfolio
  tradeShowClick(item){
    const {isTradeShow}=this.state
    this.setState({
      isTradeShow: !isTradeShow,
      accountTradeData:[item,isTradeShow],
      tradeData:[true,true,false,false],
      sliderIndex:'trade',
      isOpenAlertModalInTabletMode: false
    })
  }
  //alert--show--portfolio
  alertsShowClick(){
    this.setState({
      tradeData:[true,true,false,false],
      sliderIndex:'trade',
      isOpenAlertModalInTabletMode: true
    })
  }
  // 动态添加/取消
  chgWatchListFun(ric,tag,id){
    const {watchListData}=this.state
    let item=watchListData.find(item=>item['watchlist_id']==id)
    let ricData=item['stock_id_list']
    // 添加
    if(tag=='add'){
      ricData.unshift(ric)
    // 删除
    }else{
      for(let i in ricData){
        if(ricData[i]==ric){
          ricData.splice(i,1)  
        }
      }
    }
    this.setWatchListDataFun(watchListData)
  }
  // watchlist---set 
  setWatchListDataFun(data){
    fetch(common.getHttp+'user-watchlist',{
      method:'POST',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        token:sessionStorage.getItem('token'),
        data:data,
      })
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        this.getWatchListFun()
      }
    })
  }
  //watchlist---get
  getWatchListFun(){
    const token=sessionStorage.getItem('token');
    fetch(common.getHttp+'user-watchlist?token='+token,{
      method:'GET',
      headers:{
        'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/json',
      },
    })
    .then(res => res.json())
    .then(res => {
      //获取数据成功
      if(res['status']==0){
        let data=res['data']
        this.setState({
          watchListData:data
        })
        sessionStorage.setItem('watchListData',JSON.stringify(data))
      }
    })
  }
  getWatchListClick(){
    const {isWatchlistShow}=this.state
    this.setState({
      isSearchPageShow:true
    })
    if(isWatchlistShow){
      this.setState({
        isWatchlistShow:false
      })
      this.getWatchListFun()
    }
  }
  componentDidMount() {
    let newDate=new Date()
    let year=newDate.getFullYear()
    let month=newDate.getMonth()+1>9?newDate.getMonth()+1:'0'+String(newDate.getMonth()+1)
    let dates=newDate.getDate()>9?newDate.getDate():'0'+String(newDate.getDate())
    let nowDate=String(year)+String(month)+String(dates)
    //更改主题--setting
    let theme=localStorage.getItem('theme')
    let lang=localStorage.getItem('lang')
    let tradeName=localStorage.getItem('tradeName')
    this.setState({
      from_date:common.getLastMonthTime(),
      to_date:nowDate,
      ChgThemeData:theme?theme:'Dark',
      ChgLangData:lang?lang:'zh-CN',
      tradeName:tradeName?tradeName:'general',
    });
  }
  render() {
    const {isPC,isMobile,isTablet} = this.props;
    const {tabsData,sliderIndex,ChgLangData,from_date,to_date,ChgThemeData,isSlidingPanelShow,tradeData,tradeOrderData,accountTradeData,IsSubmitOrder,isSearchPageShow,chgSearchImg,watchListData,isGotoPageShow,tradeName,isSwitchModalShow}=this.state;
    return (
      <div 
        className={[
          APPstyles.flex_column,
          APPstyles.app_layout,
          ChgThemeData=='Light'?APPstyles.app_layout_light:'',
          ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black
        ].join(' ')}
      >
        <div ref={this.myHeaderRef} className={isPC?'':APPstyles.sticky_top_mobile}>
          <Header 
            {...this.props}
            ChgThemeData={ChgThemeData}
            sliderIndex={sliderIndex}
            isShowHeaderPage={true}
            callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          />
          {/* header--tab */}
          <div 
            className={[
              APPstyles.flex_row,
              styles.header_con,
              ChgThemeData=='Light'?APPstyles.borderBottom_light:APPstyles.borderBottom_dark,
              ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black
            ].join(' ')}
            style={{
              marginLeft:isPC?72:0
            }}
          >
            <div className={[APPstyles.flex_between,APPstyles.wPer100].join(' ')}>
              <div className={[APPstyles.flex_row,styles.header_con_left,APPstyles.wPer100].join(' ')}>
                <img 
                  src={chgSearchImg} 
                  alt='search' 
                  className={APPstyles.img24} 
                  style={{
                    margin:'0 16px',
                    display:isMobile?'none':''
                  }}
                  onClick={this.getWatchListClick.bind(this)}
                  onMouseOver={()=>{
                    if(!isPC) return
                    let imgurl=ChgThemeData=='Light'?searchOnLightImg:searchOnImg
                    this.setState({
                      chgSearchImg:imgurl
                    })
                  }} 
                  onMouseOut={()=>{
                    if(!isPC) return
                    let imgurl=isSearchPageShow?ChgThemeData=='Light'?searchOnLightImg:searchOnImg:searchImg
                    this.setState({
                      chgSearchImg:imgurl
                    })
                  }}
                />
                <div 
                  className={[APPstyles.flex_row,APPstyles.flex_1,styles.header_tabs].join(' ')}
                  style={{
                    paddingLeft:isMobile?16:0,
                    paddingRight:isMobile?16:0
                  }}
                >
                  <Tabs 
                    {...this.props} 
                    tag='news'
                    tabData={tabsData}
                    ChgThemeData={ChgThemeData}
                    isGotoPageShow={isGotoPageShow}
                    callbackTabIndex={(index)=>{
                      this.setState({
                        isSearchPageShow:false,
                        chgSearchImg:searchImg,
                      });
                    }}
                  />
                </div>
                <div className={[APPstyles.img_shadow,ChgThemeData=='Light'?APPstyles.img_shadow_light:''].join(' ')} style={{display:isMobile?'':'none'}}>
                  <img 
                    src={isMobile&&ChgThemeData=='Dark'?searchOnImg:chgSearchImg} 
                    alt='search' 
                    className={APPstyles.img24} 
                    style={{
                      margin:'0 16px'
                    }}
                    onClick={this.getWatchListClick.bind(this)}
                  />
                </div>
              </div>
              {
                isPC&&<AlertsCountButton {...this.props} ChgThemeData={ChgThemeData} callbackAlertsShow={this.alertsShowClick.bind(this)}/>
              }
            </div>
          </div>
        </div>
        <div
          className={[
            isPC?APPstyles.marginLeft72:APPstyles.marginLeft0,
            isSearchPageShow?APPstyles.flex_row:APPstyles.flex_column,
            isTablet?APPstyles.hPer_overflowH:APPstyles.flex1_hidden
          ].join(' ')}
        >
          {/* left */}
          <div
            className={[
              !isMobile&&isSearchPageShow?APPstyles.wPer30:'',
              !isSearchPageShow?'':ChgThemeData=='Light'?APPstyles.borderRight_light:APPstyles.borderRight_dark,
              isMobile&&isSearchPageShow?APPstyles.position_fixed:'',
              isMobile&&isSearchPageShow?ChgThemeData=='Light'?APPstyles.bg_light:APPstyles.bg_page_black:''
            ].join(' ')}
            style={{
              position:'relative'
            }}
          >
            {isSearchPageShow&&<SearchCon
              {...this.props}
              currentIndex={4}
              sliderIndex={'account'}
              ChgThemeData={ChgThemeData}
              isSearchPageShow={isSearchPageShow}
              watchListData={watchListData}
              cbSetWatchListFun={this.chgWatchListFun.bind(this)}
              cbCloseSearchPage={(data)=>{
                this.setState({
                  isSearchPageShow:data,
                  chgSearchImg:searchImg
                })
              }}
            />}
          </div>
          {/* right--content*/}
          <div 
            className={[
              !isMobile&&isSearchPageShow?APPstyles.wPer70:APPstyles.wPer100,
              APPstyles.HPer100
            ].join(' ')}
          >
            <ListCon 
              {...this.props} 
              from_date={from_date}
              to_date={to_date}
              ChgThemeData={ChgThemeData}
              cbChangeDateData={(data)=>{
                this.setState({
                  from_date:data[0],
                  to_date:data[1],
                });
                
              }}
            />
          </div>
        </div>
        <Slider 
          {...this.props}
          sliderIndex={sliderIndex}
          ChgThemeData={ChgThemeData}
          isShowHeaderPage={true}
          isSwitchModalShow={isSwitchModalShow}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          callbackShowSlidingPanel={this.callbackShowSlidingPanel.bind(this)}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackAlertsShow={this.alertsShowClick.bind(this)}
          callbackSetting={(data)=>{
            // 设置语言页面
            let imgurl=isSearchPageShow?data[1]=='Light'?searchOnLightImg:searchOnImg:searchImg
            this.setState({
              ChgLangData:data[0],
              ChgThemeData:data[1],
              tradeName:data[2],
              chgSearchImg:imgurl
            })
          }}
          cbSwitchMoalShow={(data)=>{
            this.setState({
              isSwitchModalShow:data,
            });
          }}
        />
        <SlidingPanel 
          {...this.props}
          ChgThemeData={ChgThemeData}
          ChgLangData={ChgLangData}
          isSlidingPanelShow={isSlidingPanelShow}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackSetting={(data)=>{
            // 设置语言页面
            let imgurl=isSearchPageShow?data[1]=='Light'?searchOnLightImg:searchOnImg:searchImg
            this.setState({
              ChgLangData:data[0],
              ChgThemeData:data[1],
              tradeName:data[2],
              chgSearchImg:imgurl
            })
          }}
        />
        {/* 遮罩层 */}
        <OrderMask
          {...this.props} 
          tradeData={tradeData}
          tradeOrderData={tradeOrderData}
          accountTradeData={accountTradeData}
          IsSubmitOrder={IsSubmitOrder}
          ChgThemeData={ChgThemeData}
          ChgLangData={ChgLangData}
          sliderIndex={sliderIndex}
          tradeName={tradeName}
          isOpenAlertModalInTabletMode={this.state.isOpenAlertModalInTabletMode}
          alertsPage={2}
          callbackChgNavIndex={this.callbackChgNavIndex.bind(this)}
          callbackPathnameApp={this.callbackPathnameApp.bind(this)}
          calkbackOrderData={(data)=>{
            //提交订单的数据--trade
            this.setState({
              tradeOrderData:data
            })
          }}
          calkbackBtnStatus={(data)=>{
            //哪个页面显示
            this.setState({
              tradeData:data
            })
            if(!data[0]){
              this.setState({
                accountTradeData:[]
              })
            }
          }}
          cbSubmitOrderFun={()=>{
            this.setState({
              IsSubmitOrder:!IsSubmitOrder,
              sliderIndex:'account'
            })
          }}
          cbGotoPage_1={()=>{
            this.gotoPageFun()
          }}
          cbSwitchMoalShow={(data)=>{
            this.setState({
              isSwitchModalShow:data,
            });
          }}
        />
      </div>
    )
  }
}
export default withTranslation()(Index);